import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function ListadoComprasCXP() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [notasCxP, setNotasCxP] = useState([]);
  const [articulos, setArticulos] = useState([]);
  
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [factura_proveedor, setFacturaProveedor] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [contenedor, setContenedor] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_compra, setNumeroCompra] = useState("");
  const [piezas, setPiezas] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosCompra, setArticulosCompra] = useState([]);
 

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_NOTAS_CXP, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNotasCxP = response.data;
        // Array para pagination
        let arrayTabla = allNotasCxP
          .sort((a, b) => (a.idNotasCxP < b.idNotasCxP ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idNotasCxP,
              fecha: a.fecha,
              observaciones: a.observaciones,
              proveedores: a.proveedores[0].nombre_comercial,
              idProveedores: a.proveedores[0]._id,
              total_cantidad: a.total_cantidad.toFixed(2),
              total_costo: a.total_costo.toFixed(2),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setNotasCxP(allNotasCxP);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          new Intl.NumberFormat("en-US").format(a.total_cantidad),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_costo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`NotasCxP.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedores: a.proveedores,
          Cantidad: a.totalCantidad,
          CostoTotal: a.total_costo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Proveedor",
          "Cantidad",
          "CostoTotal",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Proveedor",
          "Cantidad",
          "CostoTotal",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          new Intl.NumberFormat("en-US").format(a.total_cantidad),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_cantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Cantidad",
          "Costo Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas CxP.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  async function PDFOC(
    fecha,
    idProveedores,
    proveedores,
    total_cantidad,
    total_costo,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_EMBARQUES}/nota/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosEmbarque = response.data;
        let arrayTabla = allArticulosEmbarque
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cajas: (a.cantidad / a.articulos[0].piezasPorCaja).toFixed(2),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              costo: a.costo,
              recibido: a.recibido,
              pendiente_recibir: a.pendiente_recibir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let piezas = dataFinal.length

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);


        let total_importe = 0;
        let total_cajas = 0;
        const data = dataFinal.map((a) => {
            total_importe = total_importe + a.costo * a.cantidad;
            total_cajas = total_cajas + a.cajas
            return [
              a.articulos,
              a.cajas,
              a.piezasPorCaja,
              observaciones,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo * a.cantidad),
            ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Nota CxP # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(total_cantidad)} / Modelos ${piezas}`,
          20,
          35
        );
        doc.autoTable({
          head: [
            ["Articulo", "Cajas", "Piezas por Caja", "Observaciones",  "Cantidad", "Precio", "Total"],
          ],
          body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
          startY: 45,
          foot: [
            [
              "Totales",
              new Intl.NumberFormat("en-US").format(total_cajas),
              "",
              "",
              new Intl.NumberFormat("en-US").format(total_cantidad),
              "",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_importe),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Compra-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Costo Total", field: "total_costo", sortable: true },
    { name: "PDF", field: "PDF", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search)||
          comment.numero.toString().includes(search) ||
          comment.total_costo.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "totalCantidad" && sorting.field != "numero" && sorting.field != "total_costo") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "totalCantidad" || sorting.field == "numero" || sorting.field != "total_costo")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "totalCantidad" || sorting.field == "numero" || sorting.field != "total_costo")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.edoCtaProveedores ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuCXP"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Compras</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Notas CxP</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_costo)}</td>
                      <td>
                        {user.edoCtaProveedores ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.total_cantidad,
                                  a.total_costo,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                          </div>
                        ) : undefined}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

     
      {loader}
    </>
  );
}

export default ListadoComprasCXP;
