import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentaDirectaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  // const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("650a334ab99f0b3555a8cf16");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [value, setValue] = useState("");
  const [codigo, setCodigo] = useState("");
  const [articuloNombre, setArticuloNombre] = useState("");
  

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [cliente, setCliente] = useState("");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueteria, setPaqueteria] = useState("Por Definir");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [selectedAreaName, setSelectedAreaName] = useState("CEDIS");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [salidaBodega, setSalidaBodega] = useState("");
  const [plazo, setPlazo] = useState(90);
  const [comision, setComision] = useState(0);
  const [comisionPorcentaje, setComisionPorcentaje] = useState(0);
  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_cajas, setTotalCajas] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [totalCaptura, setTotalCaptura] = useState(1);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);


  const [bancos, setBancos] = useState([]);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [pago, setPago] = useState();
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [referencia, setReferencia] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("Tarjeta");
  const [saldo, setSaldo] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      articulosCodigo: "",
      observacionesArticulo: "NA",
      idInv: "",
      costoUnitario:0,
      foto:"",
      fotoArticulo: "",
      idProveedor: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    
    axios
      .get(`${URL_CLIENTES}General`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      if(selectedArea){
    axios
      .get(`${URL_INVENTARIOS}DisponibleAlmacen/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);

        setArticulos(allDisponible);
        // let result = allDisponible.map((a) => a.idArticulo);
        // setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  
  }, [selectedArea]);


  const savePedido = async (event) => {
    event.preventDefault();
    // let cantidadIF = inputFields.length;
    // let contador = 0;
    // if (cambiarPrecio == false && user.precio_menor == false) {
    //   inputFields.map(async (a) => {
    //     if (a.precioOriginal - 51 < a.precio) {
    //       return (contador = contador + 1);
    //     }
    //   });
    // } else {
    //   contador = inputFields.length;
    // }

        // if (contador == cantidadIF) {
          if (selectedCliente != "" && selectedArea != "") {
            setValidaBoton(false);
            toggleProgreso();
            let totalPedido = inputFields.length;
            try {
              await axios
                .post(
                  URL_PEDIDOS,
                  {
                    fecha,
                    clientes: selectedCliente,
                    departamentosClientes: departamentoCliente,
                    colaboradores: selectedColaborador,
                    total_general: parseFloat(total_general).toFixed(2),
                    observaciones,
                    total_cantidad,
                    paqueteria,
                    comisionTDC,
                    comisionFlete,
                    subTotal: parseFloat(subTotal).toFixed(2),
                    iva: parseFloat(iva).toFixed(2),
                    impuestos: selectedImpuesto,
                    surtido: "Si",
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    editado: "No",
                    fechaModificado: fecha,
                    creado: user.id,
                    fecha_estimada: fecha,
                    fecha_entrega: fecha,
                    entregaInsumos: "No",
                    piezasEntregadas: 0,
                    piezasPendientes: total_cantidad,
                    ventaDirecta: "Si",
                    descuento
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(async (dataPedido) => {
                  await axios
                    .post(
                      URL_SURTIDOS,
                      {
                        fecha,
                        pedidos: dataPedido.data._id,
                        observaciones,
                        user: user.id,
                        clientes: selectedCliente,
                        areas: selectedArea,
                        total_general: parseFloat(total_general).toFixed(2),
                        saldo: parseFloat(saldo).toFixed(2),
                        subTotal: parseFloat(subTotal).toFixed(2),
                        iva: parseFloat(iva).toFixed(2),
                        impuestos: selectedImpuesto,
                        is_active: "Si",
                        colaboradores: selectedColaborador,
                        comisionPagada: "No",
                        fotoEvidencia:"No",
                        mail:"No",
                        salidaBodega,
                        plazo,
                        comision,
                        comisionPorcentaje,
                        total_cantidad,
                        noGuia: "Sin Guia",
                        noFactura: "Sin Factura",
                        descuento
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((dataSurtido) => {
  
                      PDFOCSurtido(
                        dataSurtido.data.idSurtido,
                        dataSurtido.data.updatedAt,
                        dataPedido.data.idPedido
                      );
                          
                      if(dataSurtido.data.impuestos ==16){
                        enviaMail(
                        dataSurtido.data.idSurtido,
                        dataSurtido.data.updatedAt,
                        dataPedido.data.idPedido
                      );                     
                      }
  
                      inputFields.map((a) => {
                        if (a.articulos != "" && a.cantidad != 0) {
                          axios
                            .post(
                              URL_ARTICULOS_PEDIDO,
                              {
                                pedidos: dataPedido.data._id,
                                articulos: a.articulos,
                                cantidad: parseFloat(a.cantidad),
                                cantidadProcesos: 0,
                                pendiente_surtir: parseFloat(a.cantidad),
                                surtido: 0,
                                precio: parseFloat(a.precio).toFixed(2),
                                total: parseFloat(a.total).toFixed(2),
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then((dataArtPed) => {
                              axios
                                .post(
                                  URL_ARTICULOS_SURTIDOS,
                                  {
                                    fecha,
                                    surtidos: dataSurtido.data._id,
                                    inventarios: a.idInv?.length > 0 ? a.idInv : "NA",
                                    cantidad: parseFloat(a.cantidad),
                                    articulos: a.articulos,
                                    pedidos: dataPedido.data._id,
                                    articulosPedido: dataArtPed.data._id,
                                    costoUnitario: a.costoUnitario,
                                    clientes: selectedCliente,
                                    areas: selectedArea,
                                    precio: parseFloat(a.precio).toFixed(2),
                                    proveedores: a.idProveedor,
                                    colaboradores: selectedColaborador,
                                    impuestos: selectedImpuesto,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer: ${localStorage.getItem(
                                        "app_token"
                                      )}`,
                                    },
                                  }
                                )
  
                                .then((data) => {
                                  totalPedido = totalPedido - 1;
                                  if (totalPedido == 0) {
                                    axios
                                      .post(
                                        URL_LOGS,
                                        {
                                          tipo: "Crear Pedido",
                                          detalle: `${value._id} ${total_general}`,
                                          user: user.id,
                                        },
                                        {
                                          headers: {
                                            Authorization: `Bearer: ${localStorage.getItem(
                                              "app_token"
                                            )}`,
                                          },
                                        }
                                      )
                                      .then(() => {
                                        Swal.fire(
                                          "Good job!",
                                          "Creado con exito",
                                          `success`
                                        );
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      });
                                  }
                                })
                                .catch((error) => {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong!",
                                    // footer: `${error.response.data}`,
                                  });
                                  console.log(error);
                                  setValidaBoton(true);
                                });
                            });
                        } else {
                          totalPedido = totalPedido - 1;
                          if (totalPedido == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Pedido",
                                  detalle: `${value._id} ${total_general}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  `success`
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              });
                          }
                        }
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        // footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Faltan datos, favor de revisar",
            });
          }
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "No puedes vender $50 menos del precio original",
        //     showConfirmButton: false,
        //   });
        // }

   

  };

  async function PDFOCSurtido(
    numero, 
    fechaHora,
    pedido
    ) {
    let fechaHora2 = moment(fechaHora).format('YYYY-MM-DD');

    const dataIF = inputFields.map((a) => {
      if(a.cantidad > 0){
            return {
              foto: a.foto,
              fotoArticulo: a.fotoArticulo,
              cantidad: a.cantidad,
              articulos: a.articulos,
              articuloNombre: a.articulosNombre,
              articuloCodigo: a.articulosCodigo,
              cajas: a.cantidad / a.piezasPorCaja,
              idArticulo: a.idArtPed,
              precio: a.precio,
              piezasPorCaja: a.piezasPorCaja
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(dataIF);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        dataFinal.map(async (z) => {
          if(z.fotoArticulo == "Si"){
          const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.articulos}`;
         await axios
            .get(URL_GET_MEDIA, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((data) => {
              if(data.data.secure_url != null){
              z.foto = data.data.secure_url
            }else z.foto = process.env.PUBLIC_URL + "sinImagen.png"
            })
            .catch((err) => {
              console.log(err);
            });
          return z
        }else{
          z.foto = process.env.PUBLIC_URL + "sinImagen.png"
        }
        })
  
        setTimeout(() => {

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.foto,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            a.articuloCodigo,
            a.articuloNombre,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

         const columnStyles = [
          { fontSize: .000001, textColor: [255, 255, 255], cellWidth: 20, },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 18 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 85 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
        ];
        
      const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        var img2 = new Image();
        const backgroundColor = ["#d1d2d4"];
        const backgroundColorBoxLightGray = ["#e6e7e9"];
        const backgroundColorBoxBlack = ["#231f20"];
        const backgroundColorBoxPurple = ["#4d2363"];
        const margins = {  left: 5, right: 5 };

      img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
      doc.addImage(img, "jpg", 9, 12, 53, 12);

      img.src = process.env.PUBLIC_URL + "marcas.jpg";
      doc.addImage(img, "jpg", 9, 28, 192, 10);
      doc.setFontSize(10);
      
      doc.setFillColor(...backgroundColor);
      doc.rect(5, 40, 200, 6, 'F');
      doc.setTextColor("#000000");
      doc.text(`Tel: 55 2454 9626`,8, 44);
      if(selectedImpuesto == "16"){
        doc.text(`Factura: ${salidaBodega}`, 80, 44);
        }else{
        doc.text(`Remisión: ${salidaBodega}`, 80, 44);
        }

      doc.setTextColor("#000000");
      doc.text(`Cliente: ${cliente.codigo} - ${cliente.nombre_comercial}`,8, 52);

      doc.setTextColor("#4d2363");
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(`crembo.mx`,175, 44);
      doc.setTextColor("#000000");

      doc.setFontSize(14);
      doc.setFillColor(...backgroundColorBoxBlack);
      doc.rect(100, 4, 105, 22, 'F');
      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(74, 4, 25, 22, 'F');
      doc.setTextColor("#ffffff");
      doc.text(`NOTA DE SALIDA`,125, 12);
      doc.setFont('helvetica', 'normal');
      doc.text(`SALIDA BODEGA`,125, 17);
      doc.text(`México, D. F. a ${fechaHora2}`,125, 22);
        
        doc.autoTable({
          head: [[
              "",
              "CANTIDAD",
              "MODELO",
              "DESCRIPCION",
              "PRECIO UNITARIO",
              "IMPORTE",
            ]],
          body: dataPDFLimpia,
          bodyStyles:{
            rowHeight : 22,
            valign: "middle"
          },
          didDrawCell: function(data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
               var td = data.cell.raw;
               var img = td
               var dim = data.cell.height - data.cell.padding('vertical');
               var textPos = data.cell;
               doc.addImage(img, textPos.x,  textPos.y, 20, 20);
            }
          },
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: ["#4d2363"],
            lineWidth: 0,
            halign: 'center',
            valign: 'middle',
            fontSize: 8
          },
          footStyles: {
            fillColor: ["#4d2363"],
          },
          columnStyles: columnStyles,
          theme:"grid",
          margin: { left: 5, right: 5 },
          startY: 55,
          showFoot: "lastPage",

          
        });

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(5, doc.autoTable.previous.finalY +1, 36, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFontSize(8);
        doc.text(`TOTAL DE ARTICULOS`,7,  doc.autoTable.previous.finalY+5);
        doc.setTextColor("#000000");
        doc.text(`__________________________________________________________________________________`,5,  doc.autoTable.previous.finalY+22);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setFont('helvetica', 'normal');
        doc.rect(40, doc.autoTable.previous.finalY +1, 40, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US").format(total_cantidad) + " Piezas",42,  doc.autoTable.previous.finalY+5);

        doc.setFontSize(7.5);
        doc.text(`1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,5,  doc.autoTable.previous.finalY+11);
        doc.text(`2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,5,  doc.autoTable.previous.finalY+14);
        doc.text(`3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,5,  doc.autoTable.previous.finalY+17);

        var nota = "Debo(emos)  y pagare(mos)  incondicionalmente por  este  pagare a la orden  de CREMBO FASHION en esta plaza,  el día estipulado en  este documento,  la cantidad estipulada  en este  documento,  valor recibido a  mi entera satisfacción, este pagare causará intereses a razón de 2% mensual a partir de la fecha de vencimiento, hasta su total liquidación, pagadero conjuntamente con el principal. Este pagare se rige en los términos de ley general de títulos y operaciones de crédito.";
        var lines = doc.splitTextToSize(nota, 129);
        doc.text(5,doc.autoTable.previous.finalY + 26, lines);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY +1, 37, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`SUBTOTAL $`,153,  doc.autoTable.previous.finalY+5);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont('helvetica', 'normal');
        doc.rect(181, doc.autoTable.previous.finalY +1, 24, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((subTotal).toFixed(2)),183,  doc.autoTable.previous.finalY+5);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY +8, 37, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`IMPUESTO $`,154,  doc.autoTable.previous.finalY+12);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont('helvetica', 'normal');
        doc.rect(181, doc.autoTable.previous.finalY +8, 24, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((iva).toFixed(2)),183,  doc.autoTable.previous.finalY+12);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 15, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`DESCUENTO ${descuento}%`, 153, doc.autoTable.previous.finalY + 19);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 15, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(((subTotal * descuento)/ 100).toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 19
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 22, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`TOTAL $`, 153, doc.autoTable.previous.finalY + 26);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 22, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total_general.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 26
        );

        var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 40);
        doc.text(145,doc.autoTable.previous.finalY + 33, lines);

        doc.setFont('helvetica', 'bold');
        doc.text(`Nombre Completo:__________________________________________________________________________  Firma:______________________________`,5,  doc.autoTable.previous.finalY+50);

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(6);
        doc.text(`ESTE DOCUMENTO NO REPRESENTA UN COMPROBANTE FISCAL`,67,  doc.autoTable.previous.finalY+60);

        doc.save(`Nota-${numero}.pdf`);
        // doc.autoPrint();
        // window.open(doc.output("bloburl"), "_blank");

        // var att = doc.output("arraybuffer");
        // var base64File = encode(att);

        // axios
        // .post(
        //   `${URL_WHATSAPP}SendPDF`,
        //   {
        //     number: cliente.telefono,
        //     message:`Hola ${cliente.nombre_comercial}, Aquí te enviamos la nota de tu compra`,
        //     base64File,
        //     fileName:`Nota-${numero}.pdf`
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem(
        //         "app_token"
        //       )}`,
        //     },
        //   }
        // )
        //   }
        // });
        // });
      },1000)
  }

  function enviaMail(
    numero,
    fechaHora,
    pedido
  ) {
    let fechaHora2 = moment(fechaHora).format('YYYY-MM-DD');

    const dataIF = inputFields.map((a) => {
      if(a.cantidad > 0){
            return {
              foto: a.foto,
              fotoArticulo: a.fotoArticulo,
              cantidad: a.cantidad,
              articulos: a.articulos,
              articuloNombre: a.articulosNombre,
              articuloCodigo: a.articulosCodigo,
              cajas: a.cantidad / a.piezasPorCaja,
              idArticulo: a.idArtPed,
              precio: a.precio,
              piezasPorCaja: a.piezasPorCaja
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(dataIF);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let totalParcial = 0;

        dataFinal.map(async (z) => {
          if(z.fotoArticulo == "Si"){
          const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.articulos}`;
         await axios
            .get(URL_GET_MEDIA, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((data) => {
              if(data.data.secure_url != null){
              z.foto = data.data.secure_url
            }else z.foto = process.env.PUBLIC_URL + "sinImagen.png"
            })
            .catch((err) => {
              console.log(err);
            });
          return z
        }else{
          z.foto = process.env.PUBLIC_URL + "sinImagen.png"
        }
        })
  
        setTimeout(() => {

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          return [
            a.foto,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            a.articuloCodigo,
            a.articuloNombre,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(totalParcial),
          ];
        });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

         const columnStyles = [
          { fontSize: .000001, textColor: [255, 255, 255], cellWidth: 20, },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 18 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], cellWidth: 85 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
          { fontSize: 9, textColor: ["#000000"], halign: "right", cellWidth: 25 },
        ];
        
      const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        var img2 = new Image();
        const backgroundColor = ["#d1d2d4"];
        const backgroundColorBoxLightGray = ["#e6e7e9"];
        const backgroundColorBoxBlack = ["#231f20"];
        const backgroundColorBoxPurple = ["#4d2363"];
        const margins = {  left: 5, right: 5 };

      img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
      doc.addImage(img, "jpg", 9, 12, 53, 12);

      img.src = process.env.PUBLIC_URL + "marcas.jpg";
      doc.addImage(img, "jpg", 9, 28, 192, 10);
      doc.setFontSize(10);
      
      doc.setFillColor(...backgroundColor);
      doc.rect(5, 40, 200, 6, 'F');
      doc.setTextColor("#000000");
      doc.text(`Tel: 55 2454 9626`,8, 44);
      if(selectedImpuesto == "16"){
        doc.text(`Factura: ${salidaBodega}`, 80, 44);
        }else{
        doc.text(`Remisión: ${salidaBodega}`, 80, 44);
        }

      doc.setTextColor("#000000");
      doc.text(`Cliente: ${cliente.codigo} - ${cliente.nombre_comercial}`,8, 52);

      doc.setTextColor("#4d2363");
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(`crembo.mx`,175, 44);
      doc.setTextColor("#000000");

      doc.setFontSize(14);
      doc.setFillColor(...backgroundColorBoxBlack);
      doc.rect(100, 4, 105, 22, 'F');
      doc.setFillColor(...backgroundColorBoxPurple);
      doc.rect(74, 4, 25, 22, 'F');
      doc.setTextColor("#ffffff");
      doc.text(`NOTA DE SALIDA`,125, 12);
      doc.setFont('helvetica', 'normal');
      doc.text(`SALIDA BODEGA`,125, 17);
      doc.text(`México, D. F. a ${fechaHora2}`,125, 22);
        
        doc.autoTable({
          head: [[
              "",
              "CANTIDAD",
              "MODELO",
              "DESCRIPCION",
              "PRECIO UNITARIO",
              "IMPORTE",
            ]],
          body: dataPDFLimpia,
          bodyStyles:{
            rowHeight : 22,
            valign: "middle"
          },
          didDrawCell: function(data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
               var td = data.cell.raw;
               var img = td
               var dim = data.cell.height - data.cell.padding('vertical');
               var textPos = data.cell;
               doc.addImage(img, textPos.x,  textPos.y, 20, 20);
            }
          },
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: ["#4d2363"],
            lineWidth: 0,
            halign: 'center',
            valign: 'middle',
            fontSize: 8
          },
          footStyles: {
            fillColor: ["#4d2363"],
          },
          columnStyles: columnStyles,
          theme:"grid",
          margin: { left: 5, right: 5 },
          startY: 55,
          showFoot: "lastPage",
        });

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(5, doc.autoTable.previous.finalY +1, 36, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFontSize(8);
        doc.text(`TOTAL DE ARTICULOS`,7,  doc.autoTable.previous.finalY+5);
        doc.setTextColor("#000000");
        doc.text(`________________________________________________________________________________________________________________________________`,5,  doc.autoTable.previous.finalY+22);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setFont('helvetica', 'normal');
        doc.rect(40, doc.autoTable.previous.finalY +1, 40, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US").format(total_cantidad) + " Piezas",42,  doc.autoTable.previous.finalY+5);

        doc.setFontSize(7.5);
        doc.text(`1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,5,  doc.autoTable.previous.finalY+11);
        doc.text(`2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,5,  doc.autoTable.previous.finalY+14);
        doc.text(`3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,5,  doc.autoTable.previous.finalY+17);

        var nota = "Debo(emos)  y pagare(mos)  incondicionalmente por  este  pagare a la orden  de CREMBO FASHION en esta plaza,  el día estipulado en  este documento,  la cantidad estipulada  en este  documento,  valor recibido a  mi entera satisfacción, este pagare causará intereses a razón de 2% mensual a partir de la fecha de vencimiento, hasta su total liquidación, pagadero conjuntamente con el principal. Este pagare se rige en los términos de ley general de títulos y operaciones de crédito.";
        var lines = doc.splitTextToSize(nota, 129);
        doc.text(5,doc.autoTable.previous.finalY + 26, lines);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY +1, 37, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`SUBTOTAL $`,153,  doc.autoTable.previous.finalY+5);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont('helvetica', 'normal');
        doc.rect(181, doc.autoTable.previous.finalY +1, 24, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((subTotal).toFixed(2)),183,  doc.autoTable.previous.finalY+5);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY +8, 37, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`IMPUESTO $`,154,  doc.autoTable.previous.finalY+12);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont('helvetica', 'normal');
        doc.rect(181, doc.autoTable.previous.finalY +8, 24, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((iva).toFixed(2)),183,  doc.autoTable.previous.finalY+12);

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY +15, 37, 6, 'F');
        doc.setTextColor("#ffffff");
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(`TOTAL $`,159,  doc.autoTable.previous.finalY+19);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont('helvetica', 'normal');
        doc.rect(181, doc.autoTable.previous.finalY +15, 24, 6, 'F');
        doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((total_general).toFixed(2)),183,  doc.autoTable.previous.finalY+19);

        var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 40);
        doc.text(145,doc.autoTable.previous.finalY + 26, lines);

        doc.setFont('helvetica', 'bold');
        doc.text(`Nombre Completo:__________________________________________________________________________  Firma:______________________________`,5,  doc.autoTable.previous.finalY+50);

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(6);
        doc.text(`ESTE DOCUMENTO NO REPRESENTA UN COMPROBANTE FISCAL`,67,  doc.autoTable.previous.finalY+60);
// doc.save(`Nota-${numero}.pdf`);

      var att = doc.output("arraybuffer");
      var base64File = encode(att);
      axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Solicitud de factura",
          email: process.env.REACT_APP_MAIL_ITCREMBO,
          fileName: `Nota-${numero}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Le solicitamos la realizar la factura con la informacion adjunta.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    },1000)
  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        articulosCodigo: "",
        observacionesArticulo: "NA",
        idInv: "",
        costoUnitario:0,
        foto:"",
        fotoArticulo: "",
        idProveedor: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let totalCajas = values.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - descuentoNumero)) / 100);
    } else {
      setIva(0);
    }

    if (comisionPorcentaje != 0) {
      setComision((comisionPorcentaje * (TG - descuentoNumero)) / 100); 
    } else {
      setComision(0);
    }

    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * (TG- temp1)) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  };

  const handleChangeInputObservaciones = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.observacionesArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = (
          parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
        ).toFixed(2);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };
  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo.idArticulo).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosNombre = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      // articulos.map((a) => {
        // if (a._id == selectedArticulo._id) {
          let idArticulo = selectedArticulo.idArticulo;
          let piezasPorCaja = selectedArticulo.piezasPorCaja;
          let articulosNombre = selectedArticulo.nombre;
          let articulosCodigo = selectedArticulo.codigo;
          let idInv = selectedArticulo.idInv;
          let costoUnitario = selectedArticulo.costoUnitario
          let precio = selectedArticulo.venta;
          let precioOriginal = selectedArticulo.venta;
          let fotoArticulo = selectedArticulo.fotoArticulo
          let idProveedor = selectedArticulo.idProveedor
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            precioOriginal,
            articulosNombre,
            articulosCodigo,
            idInv,
            costoUnitario,
            fotoArticulo,
            idProveedor,
          );
        // }
      // });
    }
  }

  // function BuscaCodigo(id, event) {
  //   if (event.target.value.length >= 24) {
  //     if (
  //       inputFields.filter((e) => e.articulos == event.target.value).length > 0
  //     ) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "El modelo ya fue capturado!",
  //         showConfirmButton: false,
  //       });
  //       const newInputFields = inputFields.map((i) => {
  //         if (id === i.id) {
  //           i.articulosNombre = "";
  //           i.articulos = "";
  //         }
  //         return i;
  //       });
  //       setInputFields(newInputFields);
  //     } else {
  //       if (idsArticulos.includes(event.target.value)) {
  //         articulos.map((a) => {
  //           if (a._id == event.target.value) {
  //             let idArticulo = a.idArticulo;
  //             let piezasPorCaja = a.piezasPorCaja;
  //             let precio = a.venta;
  //             let precioOriginal = a.venta;
  //             let articulosNombre = a.codigo;
  //             handleChangeInputArticulo(
  //               id,
  //               idArticulo,
  //               piezasPorCaja,
  //               precio,
  //               precioOriginal,
  //               articulosNombre
  //             );
  //           }
  //         });
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "El modelo no aparece en tu catalogo",
  //           showConfirmButton: false,
  //         });
  //         const newInputFields = inputFields.map((i) => {
  //           if (id === i.id) {
  //             i.articulosNombre = "";
  //             i.articulos = "";
  //           }
  //           return i;
  //         });
  //         setInputFields(newInputFields);
  //       }
  //     }
  //   } else {
  //     const newInputFields = inputFields.map((i) => {
  //       if (id === i.id) {
  //         i.articulos = event.target.value;
  //       }
  //       return i;
  //     });
  //     setInputFields(newInputFields);
  //   }
  // }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre,
    articulosCodigo,
    idInv,
    costoUnitario,
    fotoArticulo,
    idProveedor
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.articulosCodigo = articulosCodigo;
        i.precioOriginal = precioOriginal;
        i.idInv = idInv;
        i.costoUnitario = costoUnitario
        i.fotoArticulo = fotoArticulo;
        i.idProveedor = idProveedor;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let totalCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TCajas = totalCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TCajas);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (TG - descuentoNumero)) / 100);
    } else {
      setIva(0);
    }

    if (comisionPorcentaje != 0) {
      setComision((comisionPorcentaje * (TG - descuentoNumero)) / 100); 
    } else {
      setComision(0);
    }

    let temp1 = (TG * descuento) / 100;
    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * (TG- temp1)) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);

    setSaldo(totTemp - efectivo - tdc - transfer);
  }

  function calculaDescuento(e) {
    setDescuento(e);

    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (subTotal - temp1)) / 100);
    } else {
      setIva(0);
    }

    let temp = subTotal - temp1;

    let totTemp = 
    (selectedImpuesto * (temp)) / 100 +
    temp +
    parseFloat(comisionTDC) +
    parseFloat(comisionFlete) 

    setTotalGeneral(totTemp);
    setSaldo(totTemp - efectivo - tdc - transfer)
  }

  console.log("hola",inputFields);
  const options = articulos.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  const options2 = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    setSelectedCliente(cliente);

    axios
      .get(`${URL_CLIENTES}/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        // setDescuento(allCliente.descuento);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
          })
          .catch((err) => {
            console.log(err);
          });

      }


  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * (subTotal - descuentoNumero)) / 100);
      
    } else {
      setIva(0);
    }
   

    setTotalGeneral(
      (e * (subTotal - descuentoNumero)) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete) -
        parseFloat(descuentoNumero)
    );

    setSaldo(
      (e * subTotal) / 100 +
      subTotal +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) 
    - efectivo - tdc - transfer
    )

  }
  

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (subTotal - descuentoNumero)) / 100);
    } else {
      setIva(0);
    }

    let temp = (selectedImpuesto * (subTotal - descuentoNumero)) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionFlete) -
    parseFloat((descuentoNumero * subTotal) / 100)
    
    setTotalGeneral(temp);

    setSaldo(temp - efectivo - tdc - transfer)
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * (subTotal - descuentoNumero)) / 100);
    } else {
      setIva(0);
    }

        let temp = (selectedImpuesto * (subTotal - descuentoNumero)) / 100 +
    subTotal +
    parseFloat(e) +
    parseFloat(comisionTDC) -
    parseFloat((descuentoNumero * subTotal) / 100)

    setTotalGeneral(temp);

    setSaldo(temp - efectivo - tdc - transfer)
  }

  function calculaComision(e) {
    if (e != 0) {
      setComision((e * subTotal) / 100); 
    } else {
      setComision(0);
    }

  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: "dany@sistemify.com",
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(
      total_general - e - tdc - transfer
      // - cortesia
    );
  }

  function jalaTransfer(e) {
    setTransfer(e);
    if (e > 0) {
      setDestino("");
    } else {
      setDestino("NA");
    }
    setSaldo(total_general - efectivo - tdc - e);
  }

  function jalaTDC(e) {
    setTDC(e);
    if (e > 0) {
      setTerminal("");
    } else {
      setTerminal("NA");
    }
    setSaldo(total_general - efectivo - e - transfer);
  }

  function jalaArea(e){
    setSelectedArea(e);
    areas.map((a)=>{
      if(a._id == e){
        setSelectedAreaName(a.name)
      }
    })
  }

  function importarArchivo(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];

    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });

        let A1 = hojas[0].data;

        // Crear un objeto que mapee los idArticulo de los artículos a sus objetos correspondientes

        // Si queremos comprar con nombre
        const articulosMap = {};
        inventarios.forEach((articulo) => {
          articulosMap[articulo.codigo] = articulo;
        });
        const articulosNoEncontrados = [];
        for (const a of A1) {
          const articulo = articulosMap[a.codigo];


          // Si queremos comprar con id Articulo
        // const articulosMap = {};
        // inventarios.forEach((articulo) => {
        //   articulosMap[articulo.idArticulo] = articulo;
        // });

        // const articulosNoEncontrados = [];

        // // Iterar sobre A1 una sola vez
        // for (const a of A1) {
        //   const articulo = articulosMap[a.idArticulo];


          if (articulo) {
            inputFields.push({
              id: uuidv4(),
              articulos:  articulo.idArticulo,
              piezasPorCaja: articulo.piezasPorCaja,
              cajas: a.cantidad / articulo.piezasPorCaja,
              cantidad: a.cantidad,
              precio: a.precio,
              precioOriginal: articulo.venta,
              total: a.precio * a.cantidad,
              articulosNombre: articulo.nombre,
              articulosCodigo: articulo.codigo,
              observacionesArticulo: "NA",
              idInv: articulo.idInv,
              costoUnitario: articulo.costoUnitario,
              foto:"",
              fotoArticulo: "",
              idProveedor: articulo.idProveedor,
            });
          } else {
            // // Si no se encuentra el artículo, agregarlo a la lista de no encontrados por idArticulo
            //   articulosNoEncontrados.push(a.idArticulo);


            // Si no se encuentra el artículo, agregarlo a la lista de no encontrados por nombre
            articulosNoEncontrados.push(a.codigo);
          }
        }

        // Verificar si se encontraron artículos que no estaban en el mapa
        if (articulosNoEncontrados.length > 0) {
          const mensaje = `Los siguientes articulos no se encontraron: ${articulosNoEncontrados.join(
            ", "
          )}`;
          Swal.fire({
            title: "Artículos no encontrados",
            text: mensaje,
            icon: "warning",
            button: "Entendido",
          });
        }

        ActualizaTotales();
      };
    }
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Venta Directa</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              {/* <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  jalaCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input> */}
              <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setCliente(selectedCliente);
                        setSelectedColaborador(selectedCliente.vendedor[0]._id);
                        setSelectedColaboradorName(selectedCliente.vendedor[0].nombre + " " + selectedCliente.vendedor[0].apellido);
                        setDepartamentoCliente(selectedCliente.departamentosClientes[0]._id);
                      }
                    }}
                    options={options2.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  const selectedIndex = e.target.selectedIndex;
                  const selectedOption = e.target.options[selectedIndex];
                  const selectedOptionText = selectedOption.text;
                  setSelectedColaboradorName(selectedOptionText);
                  setSelectedColaborador(e.target.value);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>


            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                type="text"
                placeholder="Paqueteria"
                value={paqueteria}
                required
                onChange={(e) => {
                  setPaqueteria(e.target.value);
                }}
              />
            </Col>
                  <Col md={1}>
                    <Label for="exampleEmail" className="mr-sm-2">
                     Salida Bodega
                    </Label>
                    <Input
                      type="text"
                      placeholder="Salida Bodega"
                      value={salidaBodega}
                      required
                      onChange={(e) => {
                        setSalidaBodega(e.target.value);
                      }}
                    />
                  </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  jalaArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a._id != AREAS_MIXCALCO) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            <Col md={1}>
              <Label>Gasto Extra</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
           
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
                <Input
                    type="select"
                    value={selectedImpuesto}
                    required
                    onChange={(e) => {
                      calculaImpuesto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="16">Si</option>
                    <option value="0">No</option>
                </Input>
            </Col>

            <Col md={1}>
              <Label>Plazo</Label>
              <Input
                type="select"
                placeholder="Plazo"
                value={plazo}
                required
                onChange={(e) => {
                  setPlazo(e.target.value);
                }}
              >
                <option value="">Plazo</option>
                <option value="0">0</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="120">120</option>
                <option value="150">150</option>
                <option value="180">180</option>
              </Input>
            </Col>
</Row>
<br />
          <Row>
            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
          <Col md={1}>
              <Label>Comision %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={comisionPorcentaje}
                onChange={(e) => {
                  setComisionPorcentaje(e.target.value);
                  calculaComision(e.target.value);
                }}
                required
              />
            </Col>
          <Col md={1}>
              <Label>Comision</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                defaultValue={0}
                decimalsLimit={2}
                value={comision}
                className={`form-control`}
                onValueChange={(value) => {
                  setComision(value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Cambio de Precio</Label>
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={(e) => toggleCambiaPrecio()}
              >
                Solicitar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Input
                required
                type="file"
                name="file"
                id="file"
                onChange={(event) => importarArchivo(event)}
              />
            </Col>
          </Row>

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Table borderless>
            <thead>
              <tr>
                {/* <th style={{ maxWidth: "120px" }}>Codigo Qr</th> */}
                <th>Buscador</th>
                <th>Articulo</th>
                <th style={{ maxWidth: "60px" }}>Disponible</th>
                {/* <th>Observaciones</th> */}
                <th style={{ maxWidth: "60px" }}>Cajas</th>
                <th style={{ maxWidth: "60px" }}>Pzs x Caja</th>
                <th style={{ maxWidth: "60px" }}>Cantidad</th>
                <th style={{ maxWidth: "60px" }}>Precio Original</th>
                <th style={{ maxWidth: "60px" }}>Precio</th>
                <th style={{ maxWidth: "80px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField) => (
                <>
                <tr>
                  {/* <td style={{ maxWidth: "120px" }}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </td> */}
                  <td>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        setCodigo(selectedArticulo.codigo);
                        setArticuloNombre(selectedArticulo.nombre);
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </td>
                  <td>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosCodigo}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input type="select" disabled>
                      {inventarios.map((a) => {
                        if (inputField.articulos == a.idArticulo) {
                          return <option>{a.disponible}</option>;
                        }
                      })}
                    </Input>
                  </td>
                  {/* <td>
                <Input
                    name="observaciones"
                    type="text"
                    placeholder="observaciones"
                    value={inputField.observacionesArticulo}
                    required
                    onChange={(event) =>
                      handleChangeInputObservaciones(inputField.id, event)
                    }
                  />
                </td> */}
                   <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                    ):(
                      <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      disabled
                    ></Input>
                    )}
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    {inputField.idInv != "" ? (
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                    ):(
                      <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      disabled
                    />
                    )}
                    
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      // bsSize="sm"
                      name="precioOriginal"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PrecioOriginal"
                      value={inputField.precioOriginal}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      style={
                        inputField.precioOriginal - 50 > inputField.precio
                          ? { backgroundColor: "#ed8c8c" }
                          : null
                      }
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "80px" }}>
                    <Input
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="total"
                      value={(inputField.total).toFixed(2)}
                      disabled
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </td>
                </tr>
                
                </>
              ))}
            </tbody>
            {/* <tr>
              <td></td>
              <td></td>
              <td style={{ maxWidth: "60px" }}></td>
                  <td style={{ maxWidth: "60px" }}>
                    <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              />
                  </td>
                </tr> */}
          </Table>
          <br />
          <Row>
            <Col md={5}></Col>
            <Col md={1}>
            <Label>Total Cajas</Label>
                  <Input
                type="number"
                min="0"
                step="any"
                placeholder="Cajas"
                value={total_cajas}
                disabled
              /></Col>
          </Row>
          <Row>
            {/* </Row> */}
            <br />


          </Row>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default VentaDirectaCreate;
