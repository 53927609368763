import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import moment from 'moment';

function AntiguedadSaldosVencido() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [formaPago, setFormaPago] = useState("");
  const [concepto, setConcepto] = useState("");


  const [movimientos, setMovimientos] = useState([]);
  const [idCliente, setIdCliente] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldo, setSaldo] = useState(0);
  const [nombreComercialCliente, setNombreComercialCliente] = useState([]);
  const [claveCliente, setClaveCliente] = useState([]);
  const [plazo, setPlazo] = useState(0);
  const [saldoInicial, setSaldoInicial] = useState(0);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [text, setText] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);


  const [totalEnTiempoGeneral, setTotalEnTiempoGeneral] = useState(0);
  const [totalSaldoGeneral, setTotalSaldoGeneral] = useState(0);
  const [totalTreintaGeneral, setTotalTreintaGeneral] = useState(0);
  const [totalSesentaGeneral, setTotalSesentaGeneral] = useState(0);
  const [totalNoventaGeneral, setTotalNoventaGeneral] = useState(0);
  const [totalCientoVeinteGeneral, setTotalCientoVeinteGeneral] = useState(0);
  const [totalCientoOchentaGeneral, setTotalCientoOchentaGeneral] = useState(0);
  const [totalMasGeneral, setTotalMasGeneral] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_DASHBOARDS}AntiguedadSaldosVencido`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
        .sort((a, b) => (a.nombreCliente > b.nombreCliente ? 1 : -1))
          .map((a) => {
            return {
              idCliente: a.clienteId,
              cliente: a.nombreCliente,
              saldo: a.saldo,
              enTiempo: a.enTiempo,
              treinta: a.treinta,
              sesenta: a.sesenta,
              noventa: a.noventa,
              cientoveinte: a.cientoveinte,
              cientoochenta: a.cientoochenta,
              otro: a.otro,
              plazo: a.plazo,
              codigo: a.codigo
            }
              
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);



        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);


  function PDFTabla() {
    const data = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.cliente,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Antiguedad Saldos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Saldo",
          "En Tiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral),
        ]
      ],
      showFoot: "lastPage",
    });
    // doc.save(`Abonos.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return {
          Cliente: a.cliente,
          Saldo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          EnTiempo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          "30": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          "60": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          "90": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          "120": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          "180": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          "+ 180": new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "AntiguedadSaldosVencido";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "AntiguedadSaldosVencido",
        sheetFilter: [
          "Cliente",
          "Saldo",
          "EnTiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
          ],
        sheetHeader: [
          "Cliente",
          "Saldo",
          "EnTiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments
    .filter(
      (comment) =>
      comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
      comment.saldo.toString().includes(search) ||
      comment.enTiempo.toString().includes(search) ||
      comment.treinta.toString().includes(search) ||
      comment.sesenta.toString().includes(search) ||
      comment.noventa.toString().includes(search) ||
      comment.cientoveinte.toString().includes(search) ||
      comment.cientoochenta.toString().includes(search) ||
      comment.otro.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedCliente == 0 || selectedCliente == a.idClientes) 
      ) {
        return [
          a.cliente,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Antiguedad Saldos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Saldo",
          "En Tiempo",
          "30",
          "60",
          "90",
          "120",
          "180",
          "+ 180",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral),
        ]
      ],
      showFoot: "lastPage",
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Antiguedad Saldos",
          email: mailTo,
          fileName: "AntiguedadSaldosVencido.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Antiguedad de Saldos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  const headers = [
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Saldo Total", field: "saldo", sortable: true },
    { name: "En Tiempo", field: "enTiempo", sortable: true },
    { name: "Vencido 30", field: "treinta", sortable: true },
    { name: "Vencido 60", field: "sesenta", sortable: true },
    { name: "Vencido 90", field: "noventa", sortable: true },
    { name: "Vencido 120", field: "cientoveinte", sortable: true },
    { name: "Vencido 180", field: "cientoochenta", sortable: true },
    { name: "Mas de 180", field: "otro", sortable: true },
    { name: "Estado de Cuenta", field: "pdf", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.saldo.toString().includes(search) ||
          comment.enTiempo.toString().includes(search) ||
          comment.treinta.toString().includes(search) ||
          comment.sesenta.toString().includes(search) ||
          comment.noventa.toString().includes(search) ||
          comment.cientoveinte.toString().includes(search) ||
          comment.cientoochenta.toString().includes(search) ||
          comment.otro.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }


    setTotalItems(computedComments.length);


    let ven = computedComments.map((c) => parseFloat(c.enTiempo));
      let Tven = ven.reduce((t, importe, index) => t + importe, 0);
      setTotalEnTiempoGeneral(Tven);

      let t = computedComments.map((c) => parseFloat(c.treinta));
      let Tt = t.reduce((t, importe, index) => t + importe, 0);
      setTotalTreintaGeneral(Tt);

      let s = computedComments.map((c) => parseFloat(c.sesenta));
      let Ts = s.reduce((t, importe, index) => t + importe, 0);
      setTotalSesentaGeneral(Ts);

      let n = computedComments.map((c) => parseFloat(c.noventa));
      let Tn = n.reduce((t, importe, index) => t + importe, 0);
      setTotalNoventaGeneral(Tn);

      let v = computedComments.map((c) => parseFloat(c.cientoveinte));
      let Tv = v.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoVeinteGeneral(Tv);

      let o = computedComments.map((c) => parseFloat(c.cientoochenta));
      let To = o.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoOchentaGeneral(To);

      let m = computedComments.map((c) => parseFloat(c.otro));
      let Tm = m.reduce((t, importe, index) => t + importe, 0);
      setTotalMasGeneral(Tm);

      setTotalSaldoGeneral(Tven + Tt + Ts + Tn + Tv + To + Tm);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "enTiempo" &&
      sorting.field != "saldo" &&
      sorting.field != "treinta" &&
      sorting.field != "sesenta" &&
      sorting.field != "noventa" &&
      sorting.field != "cientoveinte" &&
      sorting.field != "cientoochenta" &&
      sorting.field != "otro"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "enTiempo" ||
        sorting.field == "saldo" ||
        sorting.field == "treinta" ||
        sorting.field == "sesenta" ||
        sorting.field == "noventa" ||
        sorting.field == "cientoveinte" ||
        sorting.field == "cientoochenta" ||
        sorting.field == "otro")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "enTiempo" ||
        sorting.field == "saldo" ||
        sorting.field == "treinta" ||
        sorting.field == "sesenta" ||
        sorting.field == "noventa" ||
        sorting.field == "cientoveinte" ||
        sorting.field == "cientoochenta" ||
        sorting.field == "otro")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
  ]);

  async function jalaMov(id, cliente, plazo, saldo, nombreComercial, clave) {
    await axios
      .get(`${URL_ESTADO_CUENTA}/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(cliente)
    setNombreCliente(cliente);
    setNombreComercialCliente(nombreComercial);
    setClaveCliente(clave);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    toggle();
  }

  async function PDFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

   await axios
      .get(`${URL_DASHBOARDS}AntiguedadSaldosVencidoEdoCta/${idCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAntSaldos = response.data;

      let totalTreinta = 0;
      let totalSesenta = 0;
      let totalNoventa = 0;
      let totalMasNoventa = 0;
      let totalEnTiempo = 0;

      const dataAS = allAntSaldos.map((a) => {
        totalTreinta += isFinite(a.treinta) ? a.treinta : 0;
        totalSesenta += isFinite(a.sesenta) ? a.sesenta : 0;
        totalNoventa += isFinite(a.noventa) ? a.noventa : 0;
        totalMasNoventa += isFinite(a.masnoventa) ? a.masnoventa : 0;
        totalEnTiempo += isFinite(a.enTiempo) ? a.enTiempo : 0;
        const fechaVencimientoDate = new Date(a.fechaVencimiento);

        const fechaVencimientoFormatted = fechaVencimientoDate.toLocaleDateString('en-GB');
      
        const treintaFormatted = isFinite(a.treinta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta) : '';
        const sesentaFormatted = isFinite(a.sesenta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta) : '';
        const noventaFormatted = isFinite(a.noventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa) : '';
        const masnoventaFormatted = isFinite(a.masnoventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.masnoventa) : '';
        const enTiempoFormatted = isFinite(a.enTiempo) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo) : '';
      
        return [
          a.folio,
          fechaVencimientoFormatted,
          treintaFormatted,
          sesentaFormatted,
          noventaFormatted,
          masnoventaFormatted,
          enTiempoFormatted
        ];
      });
      const dataPDFAS = Object.values(dataAS);
      const dataPDFLimpiaAS = dataPDFAS.filter(function (el) {
        return el != null;
      });

      const totalVencido = totalTreinta + totalSesenta + totalNoventa + totalMasNoventa;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = a.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo): '';
        const abonos = a.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono): '';
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.numero,
          a.fecha,
          a.movimiento,
          cargos,
          abonos,
          saldoParcialFormato,
          a.observaciones
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const columnStyles = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'}, //Cargos - Negro
      { fontSize: 9,textColor: ["#f40903"] ,halign: 'right'}, // Abonos - Rojo
      { fontSize: 9,textColor: ["#030af4"] ,halign: 'right'}, // Saldos - Azul a favor
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
  ];

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    var img2 = new Image();
    const backgroundColor = ["#d1d2d4"];
    const backgroundColorBoxBlack = ["#231f20"];
    const backgroundColorBoxPurple = ["#4d2363"];
    const margins = {  left: 5, right: 5 };

    img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
    doc.addImage(img, "jpg", 9, 12, 53, 12);

    img.src = process.env.PUBLIC_URL + "marcas.jpg";
    doc.addImage(img, "jpg", 9, 28, 192, 10);
    doc.setFontSize(10);
    
    doc.setFillColor(...backgroundColor);
    doc.rect(5, 40, 200, 6, 'F');
    doc.setTextColor("#000000");
    doc.text(`Tel: 55 2454 9626`,8, 44);

    doc.setTextColor("#000000");
    doc.text(`Cliente: ${nombreComercialCliente}`,8, 52)
    doc.text(`Clave: ${claveCliente}`,176, 52)

    doc.setTextColor("#4d2363");
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`crembo.mx`,175, 44);
    doc.setTextColor("#000000");

    doc.setFontSize(14);
    doc.setFillColor(...backgroundColorBoxBlack);
    doc.rect(100, 4, 105, 22, 'F');
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(74, 4, 25, 22, 'F');
    doc.setTextColor("#ffffff");
    doc.text(`Estado de Cuenta`,130, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA",
          "MOVIMIENTO",
          "CARGOS",
          "ABONOS",
          "SALDO",
          "OBSERVACIONES"
        ],
      ],
      body: dataPDFLimpia,
      startY: 55,
      headStyles: {
        // fillColor: ["#723C85"],
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        // fillColor: ["#723C85"],
        fillColor: ["#4d2363"],
      },
      columnStyles: columnStyles,
      margin: margins,
      theme:"grid",
    });

    const columnStylesAntiguedad = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
  ];

    doc.addPage()
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(8, 8, 70, 10, 'F');
    doc.setFontSize(14);
    doc.setTextColor("#ffffff");
    doc.text(`ANTIGUEDAD DE SALDOS`,10, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA DE VENCIMIENTO",
          "1 A 30 DIAS",
          "31 A 60 DIAS",
          "61 A 90 DIAS",
          "+ 90 DIAS",
          "EN TIEMPO",
        ],
      ],
      body: dataPDFLimpiaAS,
      startY: 20,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        fillColor: ["#4d2363"],
      },
      columnStyles:columnStylesAntiguedad,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTreinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalMasNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
        ]
      ],
      showFoot: "lastPage",
      margin: margins,
      theme:"grid",
    });

   
let totalVencidos = totalTreinta + totalSesenta + totalNoventa + totalMasNoventa;
let totalResumen = totalVencidos + totalEnTiempo;

// Asumimos que totalVencidos y totalEnTiempo ya están calculados correctamente como sumas de montos.
let porcentajeVencidos = totalResumen > 0 ? (totalVencidos / totalResumen) : 0;
let porcentajeAtiempo = totalResumen > 0 ? (totalEnTiempo / totalResumen) : 0;

const resumenData = [
  ["Vencidos",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalVencidos),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(porcentajeVencidos)],
  ["Al corriente",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(porcentajeAtiempo)],
  ["Total",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalResumen),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(1)]
];

    const columnStylesResumen = [
      { fontSize: 9,textColor: ["#FFFFFF"] ,fillColor: ["#4d2363"],halign: 'center'},
      { fontSize: 9,textColor: ["#000000"],halign: 'right'},
      { fontSize: 9,textColor: ["#000000"],halign: 'center'},
  ];

    doc.autoTable({
      head: [
        [
          "",
          "RESUMEN DE SALDOS",
          ""
        ]
      ],
      body: resumenData,
      startY: doc.autoTable.previous.finalY + 10,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      columnStyles:columnStylesResumen,
      showFoot: "lastPage",
      margin:  {  left: 5, right: 120 },
      theme:"grid",
    });

    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);

  })
  .catch((err) => {
    console.log(err);
  });
  }

  function emailFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;
    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 50,
      headStyles: {
        fillColor: ["#723C85"],
        lineWidth: 0
      },
      footStyles: {
        fillColor: ["#723C85"],
      },
      theme:"grid",
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function countOfEachColumn(arr) {
    const columnCounts = [];
  
    for (let j = 2; j < arr[0].length; j++) {
      let columnCount = 0;
  
      for (let i = 0; i < arr.length; i++) {
        const currentValue = arr[i][j];
        if (currentValue > '') {
        columnCount += 1;
        }
      }
  
      columnCounts.push(columnCount);
    }
  
    return columnCounts ;
  }



  let totalEnTiempo = 0;
  let totalSaldo = 0;
  let totalTreinta = 0;
  let totalSesenta = 0;
  let totalNoventa = 0;
  let totalCientoVeinte = 0;
  let totalCientoOchenta = 0;
  let totalMas = 0;

  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const secondDate = new Date(hoy);
  let saldoVencido =0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        
          <h3 align="center">Antiguedad de Saldos Vencido</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
   
            <tbody>
              {commentsData.map((a) => {
               
                  totalEnTiempo = totalEnTiempo + a.enTiempo;
                  totalSaldo = totalSaldo + a.saldo;
                  totalTreinta = totalTreinta + a.treinta;
                  totalSesenta = totalSesenta + a.sesenta;
                  totalNoventa = totalNoventa + a.noventa;
                  totalCientoVeinte = totalCientoVeinte + a.cientoveinte;
                  totalCientoOchenta = totalCientoOchenta + a.cientoochenta;
                  totalMas = totalMas + a.otro;
     
                    return (
                      <tr>
                        <td>{a.cliente}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.saldo)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoveinte)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.cientoochenta)}</td>
                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.otro)}</td>
                      <td>
                      <Button
                          color="info"
                          size="sm"
                          onClick={(e) =>
                            jalaMov(a.idCliente, a.cliente, a.plazo, a.saldo, a.cliente, a.codigo)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>
                      </td>
                      
                      </tr>
                    );
  
 
              })}
              <tr>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldo)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempo)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreinta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesenta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventa)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinte)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchenta)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMas)} </td>
                <td></td>
              </tr>

              <tr>
                <td className="negrita" align="center">
                  TOTAL GENERAL
                </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral)}</td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral)} </td>
                <td></td>
              </tr>

              <tr>
                <td className="negrita" align="center">
                  PORCENTAJE
                </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSaldoGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral / totalSaldoGeneral)}</td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalTreintaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSesentaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalNoventaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalMasGeneral / totalSaldoGeneral)} </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
        <ModalHeader toggle={toggleMail2}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOC}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
    
      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={2}>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>


            <Col md={2}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla">Folio</th>
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                  <th className="tituloTabla">Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.ceil((secondDate - firstDate) / oneDay))
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 0 &&  diffDays > plazo){
                      saldoVencido = 0
                      saldoVencido = saldoVencido + c.saldo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="right">
                            {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                          </td>
                          <td align="right">
                            {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                saldoParcialTabla
                              )}
                          </td>
                          <td align="center">{c.observaciones}</td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.numero}</td>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="right">
                          {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                        </td>
                        <td align="right">
                          {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              saldoParcialTabla
                            )}
                        </td>
                        <td align="center">{c.observaciones}</td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Vencido
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
     
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Antiguedad Saldos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

      {loader}
    </>
  );
}

export default AntiguedadSaldosVencido;
